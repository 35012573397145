import React from "react";

const Footer = () => {
  return (
    <div className="ss-footer">
      <div className="ss-container">
        <div className="ss-row">
          <span> SS. </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
